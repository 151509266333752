<template>
    <v-main class="d-flex " style="min-height: 300px;">
      <v-container class="outer-container">
    <v-row >
      <v-col cols="6" xs="12">
        <v-btn @click="save()"  color="primary">
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12" sm="6">
        <h1><v-text-field v-model="teamConfig.name" label="Team Name"></v-text-field></h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6" >
        <v-card title="Groups" variant="">
          <v-card-text>
            <v-text-field v-model="newGroupName" label="New group name" append-icon="mdi-plus"
              @click:append="addGroup"></v-text-field>
            <ul>
              <li v-for="(item, index) in teamConfig.groups" :key="item">
                <v-btn size="24px" @click="removeGroup(index)">
                  <v-icon icon="mdi-delete"></v-icon>
                </v-btn>
                {{ item }}
              </li>
            </ul>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <v-card title="Coaches" variant="">
          <v-card-text>
            <v-text-field v-model="newCoach" label="New coach email" append-icon="mdi-plus" type="email"
                          @click:append="addCoach"></v-text-field>
            <ul>
              <li v-for="(item, index) in teamConfig.coaches" :key="item">
                <v-btn size="24px" @click="removeCoach(index)">
                  <v-icon icon="mdi-delete"></v-icon>
                </v-btn>
                {{ item.email }}
              </li>
            </ul>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
    <v-row class="row" no-gutters>
      <v-col cols="12" sm="3">
        <v-card title="Configuration" variant="">
          <v-card-text>
            <v-text-field type="number" label="Water Break Length" v-model="teamConfig.waterBreakLength"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-row class="row" no-gutters>
      <v-col cols="12" sm="3">
        <v-card title="Practice Templates" variant="">
          <v-card-text>
            <v-btn @click="newTemplate()"  color="primary">
              New
            </v-btn>
            <ul>
              <li v-for="(template) in templates" :key="template.id">
                <router-link :to="{ name: 'teamedit', params: { id: template.id } }">
                  {{template.name}}
                </router-link>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

  </v-container>
    </v-main>

</template>
    
<script>

import api from '../api'

function getTeam(id, resultHandler) {

  if (id === "new") {
    resultHandler({
      name: "",
      waterBreakLength: 5,
    }, null);
  } else {
    api.teams.get(id, resultHandler)
  }

}

import {currentUserStore} from "@/stores/currentUserStore";

export default {
  name: 'TeamEdit',
  data() {
    return {
      teamConfig: {},
      templates:[],
    }
  },
  beforeRouteEnter(to, _from, next) {
    getTeam(to.params.id, (team, err) => {
      next(vm => vm.setData(err, team))
    })
  },
  async beforeRouteUpdate(to, _from) {
    this.post = null
    try {
      this.post = await getTeam(to.params.id)
    } catch (error) {
      this.error = error.toString()
    }
  },
  methods: {
    save(){
      console.log("about to save team")
      api.teams.save(this.teamConfig, ()=>{
        currentUserStore().refresh( () =>{
          this.$router.push("/");
        });

      }, (e) =>{
        this.$toast.open({ message: 'Unable to save team: ' + e.response.data.message, type: 'error', duration:5000 });
      })

    },
    removeGroup(index) {
      this.teamConfig.groups.splice(index, 1);
    },
    removeCoach(index) {
      this.teamConfig.coaches.splice(index, 1);
    },
    addGroup() {
      if (!this.teamConfig.groups) {
        this.teamConfig.groups = [];
      }

      if (!this.newGroupName) {
        return;
      }

      this.teamConfig.groups.push(this.newGroupName);
      this.newGroupName = null;
    },
    addCoach() {
      if (!this.teamConfig.coaches) {
        this.teamConfig.coaches = [];
      }

      if (!this.newCoach) {
        return;
      }

      this.teamConfig.coaches.push({"email":this.newCoach});
      this.newCoach = null;
    },
    setData(error, post) {
      if (error) {
        this.error = error
      } else {
        this.teamConfig = post
      }
    },
    newTemplate() {
      api.templates.new(this.teamConfig.id, (template)=>{
        this.$router.push({name: 'templateedit', params: {teamid: this.teamConfig.id, templateid: template.id}})
      }, (e) =>{
        this.$toast.open({ message: 'Unable to create new template: ' + e.response.data.message, type: 'error', duration:5000 });
      })

    },
  }
}
</script>
        <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>