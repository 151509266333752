<template>
  <v-app>
    <Header />
    <div >
    <router-view ></router-view>
    </div>
  </v-app>
 </template>


<script>


import Header from '@/components/PageHeader.vue'
import { currentUserStore } from './stores/currentUserStore'

import api from './api'
 
 export default {
  name: 'App',
  components: { 
    // Footer,
    Header 
  },
  data() {
    return {
      user: null,
    }
  },
  mounted(){
    api.me.get(response => {
      currentUserStore().currentUser = response
    },
err =>{
      if(this.$route.name !== "sharedview"){
      window.location = "/login"
    }

    })
  },

 }
 </script>