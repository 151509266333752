<template>
    {{ startTimeDisplay }} - {{ endTimeDisplay }}
</template>
     
<script>

function calculateStartTime(timeSlots, startTime, index) {

    let start = startTime;
    if(typeof start === "string"){
      start = new Date(start);
    } else {
      start = new Date(startTime.getTime());
    }

    for (let x = 0; x < index; x++) {
      timeSlots[x].duration = parseInt(timeSlots[x].duration)
      start = new Date(start.setMinutes(start.getMinutes() + timeSlots[x].duration));
    }

    try{
        return Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(start).replace("AM", "").replace("PM", "");
    }catch{
        return ""
    }

}

function calculateEndTime(timeSlots, startTime, index, duration) {
    let start = startTime;
    if(typeof start === "string"){
      start = new Date(start);
    } else {
        start = new Date(startTime.getTime());
      }

    duration = parseInt(duration)

    for (let x = 0; x < index; x++) {
        start = new Date(start.setMinutes(start.getMinutes() + timeSlots[x].duration));
    }

    try{
      return Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(new Date(start.setMinutes(start.getMinutes() + duration))).replace("AM", "").replace("PM", "");
    }catch(ex){
      console.error(ex)
        return ""
    }
    
}

export default {
    name: 'TimeDisplay',
    props: ['timeSlots', 'index', 'duration', 'practiceStartTime'],

      mounted() {
        this.startTimeDisplay = calculateStartTime(this.timeSlots, this.practiceStartTime, this.index)
        this.endTimeDisplay = calculateEndTime(this.timeSlots, this.practiceStartTime, this.index, this.duration);
    },
    data() {
        return {
            startTimeDisplay: "",
            endTimeDisplay: "",
        }
    },
    watch: {
        timeSlots: {
            handler() {
                this.startTimeDisplay = calculateStartTime(this.timeSlots, this.practiceStartTime, this.index)
                this.endTimeDisplay = calculateEndTime(this.timeSlots, this.practiceStartTime, this.index, this.duration);
            },
            deep: true
        },
      duration: {
        handler() {
          this.startTimeDisplay = calculateStartTime(this.timeSlots, this.practiceStartTime, this.index)
          this.endTimeDisplay = calculateEndTime(this.timeSlots, this.practiceStartTime, this.index, this.duration);
        },
        deep: true
      },
      practiceStartTime: {
        handler() {
          this.startTimeDisplay = calculateStartTime(this.timeSlots, this.practiceStartTime, this.index)
          this.endTimeDisplay = calculateEndTime(this.timeSlots, this.practiceStartTime, this.index, this.duration);
        },
        deep: true
      }
    }
}
</script>
     
     <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
     