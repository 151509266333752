<template>
  {{ timeDisplay }}
</template>

<script>

function calculateDisplayTime(time){
  if(time){
    let d = new Date(time);

    if (d) {
      return  Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(d).replace(",", "");
    }
  }

  return ""
}

export default {
  name: 'DateTimeDisplay',
  props: ['time'],
  mounted(){
    this.timeDisplay = calculateDisplayTime(this.time)
  },
  watch: {
    time: {
      handler() {
        this.timeDisplay = calculateDisplayTime(this.time)
      },
      deep: true
    }
  },
  data() {

    return {
      timeDisplay: null,

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
