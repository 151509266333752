import {defineStore} from "pinia";
import {ref} from "vue";


import api from '../api'


export const currentUserStore = defineStore('currentuser', () => {
    let currentUser = ref({})

    function refresh(callback){
        api.me.get(response => {
            currentUser = response;
            callback();
        } )
    }

    return { currentUser, refresh }
})
