import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import { createVuetify } from 'vuetify'
// import '../styles/main.scss'
// import 'vuetify/styles'
import '../styles/settings.scss'
import '../styles/main.scss'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {  aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        },
    },
    defaults:{
        global:{
            density: "compact",
        },
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: "#7d46ba",
                },
                variables: {
                    'high-emphasis-opacity': 1,
                    'medium-emphasis-opacity': 1,
                },
            },
        },
    }
})
