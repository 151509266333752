<template>
  <v-app-bar style="background: linear-gradient(270deg, rgba(48,53,101,1) 0%, rgba(148,75,211,1) 100%);" absolute>
    <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"  v-if="isSharedView === false"></v-app-bar-nav-icon>

    <v-app-bar-title>
      Team Practice Plan
    </v-app-bar-title>

  </v-app-bar>

  <v-navigation-drawer v-model="drawer" temporary>
    <v-list-item >
      <v-list-item-icon>
      <v-icon>{{ user.image}}</v-icon>
    </v-list-item-icon>

    <v-list-item-conπtent>
      <v-list-item-title>{{ user.name }}</v-list-item-title>
    </v-list-item-conπtent>
      </v-list-item >

    <v-divider></v-divider>
    <v-list-item >
      <router-link :to="{ name: 'home' }">
              Home
      </router-link>
    </v-list-item>
    <v-list-item >
      <router-link :to="{ name: 'drilllibrary' }">
              Drill Library
      </router-link>
    </v-list-item>
    <v-list-item title="Teams" value="contacts">
        <ul>
                  <li class='team-item' v-for="(team) in user.teams" :key="team.id">

                    <router-link :to="{ name: 'teamedit', params: { id: team.id } }">
                      {{team.name}}
                    </router-link>
                  </li>
                </ul>
    </v-list-item>

  </v-navigation-drawer>


</template>

<script>
  import { ref } from 'vue';
  import { currentUserStore } from '../stores/currentUserStore'

  export default {
    name: 'PageHeader',
    data() {
     return {
        drawer:false,
        userdrawer:false,
        user: ref({}),
        isSharedView: false,
     }
    },
    mounted(){
      let self =  this;
      this.user = currentUserStore().currentUser;

      currentUserStore().$subscribe(() => {
        self.user = currentUserStore().currentUser;
      });
    },
    created() {
      this.$watch(
          () => {
            this.isSharedView = this.$route.name === "sharedview";
          },
          () => {
            this.isSharedView = this.$route.name === "sharedview";
          },
          { immediate: true }

      )
    },
    
  }
  </script>

<style scoped>
header {
  margin-bottom: 75px;

}

.team-item{
    margin-left:10px;
    list-style: none;
}
</style>
    