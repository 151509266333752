<template>
    <v-card>
      <form  novalidate v-if="isInitial">
        <h1>Upload image</h1>

        <div class="dropbox">
          <input type="hidden"  name="key" value="user/user1/${filename}" /><br />
          <input type="hidden" name="acl" value="public-read" />
          <input type="hidden" name="x-amz-server-side-encryption" value="AES256" />
          <input type="hidden"   name="X-Amz-Credential" value="" />
          <input type="hidden"   name="X-Amz-Algorithm" value="AWS4-HMAC-SHA256" />
          <input type="hidden"   name="X-Amz-Date" value="" />

          <input type="hidden" name="Policy" value='' />
          <input type="hidden" name="X-Amz-Signature" value="" />

          <input autofocus id="pasteArea" type="file" name="file" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                 accept="image/*" class="input-file" >
          <p v-if="isInitial">
            <span v-if="isMobile">
              Click to browse or take image
            </span>
            <span v-if="!isMobile">
            Drag your image here, click to browse or paste from your clipboard
            </span>

          </p>
          <input @paste="onPaste" v-focus class="paste-input">
        </div>
      </form>
      <div v-if="isSaving">
        <v-progress-linear
            :active="true"
            color="primary"
            height="4"
            indeterminate
        ></v-progress-linear>
        Uploading image...

      </div>

    </v-card>
</template>

<script>
import api from '../../api'

const STATUS_INITIAL = 0, STATUS_SAVING = 1;

export default {
  name: 'FileUpload',
  props: ['teamId'],
  methods: {
    onPaste (event) {
      console.log('on paste', event)
      // use event.originalEvent.clipboard for newer chrome versions
      var items = (event.clipboardData  || event.originalEvent.clipboardData).items;
      console.log(JSON.stringify(items)); // will give you the mime types
      // find pasted image among pasted items
      var blob = null;
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
        }
      }
      // // load image if there is a pasted image
      if (blob !== null) {
        // var reader = new FileReader();
        // reader.onload = function(event) {
        //   console.log(event.target.result); // data url!
        //   document.getElementById("pastedImage").src = event.target.result;
        // };
        // reader.readAsDataURL(blob);
        let formData = this.newFormData();
        formData.append("file", blob, "clipboard.png");
        this.save(formData);
      }
    },
    reset(){
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.showImageAdd = true;
    },
    newFormData(){
      let formData = new FormData();
      formData.append("key", "");
      formData.append("X-Amz-Credential", "");
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", "");
      formData.append("Policy", "");
      formData.append("X-Amz-Signature", "");
      formData.append("X-Amz-Security-Token", "");

      return formData;
    },
    filesChange(fieldName, fileList) {
      // handle file changes

      console.log("upload files changed");


      if (!fileList.length) return;

      if(fileList[0].type.indexOf("image/") < 0){
        return;
      }

      let formData = this.newFormData();

      // append the files to FormData
      Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });

      // save it
      this.save(formData);
    },
    save(formData){
      console.log(formData)
      this.currentStatus = STATUS_SAVING;

      api.files.uploadImage(this.teamId, formData, (result)=>{
        console.log("upload complete",result );
        this.$emit('uploadComplete' , result);
      }, (err) =>{
        console.log(err)
        this.currentStatus = STATUS_INITIAL;
      })


    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      isMobile:false,
    }
  },
  mounted() {
    this.reset();
    this.isMobile = this.$vuetify.display.mobile;
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: #e9e9e9;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  margin:10px;
  padding:20px;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.paste-input{
  opacity: 0; /* invisible but it's there! */
}

.dropbox:hover {
  background: #d0d0d0; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

h1{
  margin:10px;
}
</style>
