<template>
  <v-main class="d-flex " style="min-height: 300px;">

  <v-container fluid >
    <v-row >
      <v-col cols="6" xs="12">
        <v-btn @click="save()"  color="primary">
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-row >
       <v-col class='pa-1'  cols="10">
        <v-text-field type="number" label="Name" v-model="name"
                      hide-details="auto"></v-text-field>
      </v-col>

    </v-row>

    <v-row v-for="(timeSlot) in timeSlots" :key="timeSlot">

      <v-col class='pa-1'  cols="1">
        <v-text-field type="number" label="Duration" v-model="timeSlot.duration"
                      hide-details="auto"></v-text-field>
      </v-col>
      <v-col class='pa-1'  cols="9">
        <v-text-field type="number" label="Description" v-model="timeSlot.description"
                      hide-details="auto"></v-text-field>
      </v-col>

    </v-row>
  </v-container>
  </v-main>
</template>
  
<script>
import api from '../../api'

function getPracticePlanTemplate(teamId, templateId, resultHandler) {
  api.templates.get(teamId, templateId, resultHandler)
}
export default {
  name: 'PracticePlanTemplate',
  beforeRouteEnter(to, _from, next) {
    getPracticePlanTemplate(to.params.teamid, to.params.templateid, (team, err) => {
      next(vm => vm.setData(err, team))
    })
  },
  async beforeRouteUpdate(to, _from) {
    this.post = null
    try {
      this.post = await getPracticePlanTemplate(to.params.teamid, to.params.templateid)
    } catch (error) {
      this.error = error.toString()
    }
  },
  data() {
    return {
      name:"",
      timeSlots: [{},{},{},{}],
    }
  },
  methods: {
    save() {
    }
  }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.is-break {
  color: black;
  background-color: lightgray;
}

.time-slot-row{
  border-bottom: 1px solid grey;
}

</style>
  