
import { createRouter, createWebHashHistory } from 'vue-router';

import PracticePlan from './components/practiceplan/PracticePlan';
import PracticePlanView from './components/practiceplan/PracticePlanView';
import PracticePlanTemplate from './components/practiceplan/PracticePlanTemplate';
import Home from './components/HomePage';
import TeamEdit from './components/TeamEdit';
import DrillLibrary from "./components/DrillLibrary";

const routes = [
    { name: "home", path: '/', component: Home },
    { name: "planedit", path: '/plan/:id', component: PracticePlan },
    { name: "planview", path: '/view/:id', component: PracticePlanView },
    { name: "teamedit", path: '/team/:id', component: TeamEdit },
    { name: "templateedit", path: '/team/:teamid/template/:templateid', component: PracticePlanTemplate },
    { name: "sharedview", path: '/share/:id', component: PracticePlanView },
    { name: "drilllibrary", path: '/drilllibrary', component: DrillLibrary },

]

export default createRouter({
    history: createWebHashHistory(),
    routes,
})

